/* You can add global styles to this file, and also import other style files */
@import "./../node_modules/@angular/material/legacy-prebuilt-themes/legacy-indigo-pink.css";

@font-face {
  font-family: bahnschrift;
  src: url(./assets/font/BAHNSCHRIFT.TTF) format("truetype");
}

* {
  font-family: bahnschrift, serif;
}

/* for sidenav to take a whole page */
html, body {
  margin: 0;
  height: 100%;
  width: 100%!important;
}

p {
  margin-block-start: 2px;
  margin-block-end:  2px;
}

.loginDialog .mat-dialog-container {
  background-image: linear-gradient(to right, rgb(0,48,91), rgb(0,27,55)) ;
  border: 1px solid white;
  border-radius: 10px;
  color: white;
  fill: white;
  max-height: 90%;
}

.loginDialogGray .mat-dialog-container {
  background: rgb(230,230,230);
  border: 1px solid black;
  border-radius: 10px;
  color: #464f56 !important;
  fill: black;
  max-height: 90%;
}

.settingControl .mat-dialog-container {
  background: rgb(230,230,230);
  border: 1px solid black;
  border-radius: 10px;
  color: #464f56 !important;
  fill: black;
  max-height: 90%;
  position: absolute;
  left: 10px;
  top: 20px;
  width: auto;
  height: auto;
}

::-webkit-scrollbar {
  width: 0px !important;
}

.activeColour:active {
  filter: invert(53%) sepia(94%) saturate(592%) hue-rotate(161deg) brightness(95%) contrast(94%) !important;
}

.inventoryTheme {
  background-image: linear-gradient(90deg ,#5d5d5d,#5d5d5d)!important;
  fill: white!important;
}

.conveyorSelectionBG {
    background-image: linear-gradient(90deg ,#193153, #1a2b3c);
    border: 2px solid white;
    border-radius: 5px;
}

.mat-icon-button {
  background-color: transparent;
}

.mat-button {
  background-color: transparent;
}

/* Styles used to migrate away from fxLayout */
.flex-row { display: flex; flex-direction: row; box-sizing: border-box; }
.flex-col { display: flex; flex-direction: column; box-sizing: border-box; }
.grid { display: grid; }
.flex-wrap { flex-flow: wrap; }
.flex-column-wrap { flex-flow: column wrap; }
.flex-col-xs { @media screen and (max-width: 599px) { flex-direction: column; } }

/* Gap */
.gap-5 { gap: 5px; }
.gap-8 { gap: 8px; }
.gap-10 { gap: 10px; }
.gap-12 { gap: 12px; }
.gap-16 { gap: 16px; }
.gap-32 { gap: 32px; }
.gap-50 { gap: 50px; }

.space-between { justify-content: space-between; }
.stretch-col {max-width: 100%; }
.stretch-row {max-height: 100%; }
.stretch-self {align-self: stretch; }
.center-self {align-self: center; }
.end-self {align-self: flex-end; }
.start-self {align-self: flex-start }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.justify-start { justify-content: flex-start; }
.items-center { align-items: center; }

.flex-1 { flex: 1 }
.flex-grow { flex-grow: 1; }
.flex-0-1-50-100 { flex: 0 1 calc(50% - 100px) }
.flex-0-1-50-50 { flex: 0 1 calc(50% - 50px) }
.flex-0-1-25-32 { flex: 0 1 calc(25% - 32px) }
.flex-0-1-20-50 { flex: 0 1 calc(20% - 50px) }
.flex-0-1-33-32 { flex: 0 1 calc(33.3% - 32px) }



